// ContactForm.tsx

import React from 'react';

const ContactForm: React.FC = () => {
  return (
    <form className="contactForm" action="https://formsubmit.co/a83470af927f7f203b1fc362fdd17933" method="POST">
      <label htmlFor="name">Name</label>
      <input type="text" id="name" name="name" required />

      <label htmlFor="company">Company</label>
      <input type="text" id="company" name="company"/>

      <label htmlFor="email">Email</label>
      <input type="email" id="email" name="email" required/>

      {/*
      <label htmlFor="assistance">What do you need help with?</label>
      <select id="assistance" name="assistance" required>
        <option value="" disabled selected>Select from dropdown</option>
        <option value="consultation">Consultation</option>
        <option value="project">Project Development</option>
      </select>
      */}
      {/* <label htmlFor="location">Where are you based?</label>
      <input type="text" id="location" name="location" /> */}
      
      <label htmlFor="project">A few words about the project</label>
      <textarea id="project" name="project" required></textarea>
      
      {/* <label htmlFor="referral">Where did you hear about me?</label>
      <select id="referral" name="referral" required>
        <option value="" disabled selected>Where did you hear about me?</option>
        <option value="linkedin">LinkedIn</option>
        <option value="word_of_mouth">Word of Mouth</option>
      </select> */}
      
      <button type="submit" className="submitBtn">Submit</button>
    </form>
  );
};

export default ContactForm;
