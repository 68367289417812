import React, { useEffect } from 'react';
import './App.css';
import './styles/Header.css'
import './styles/Hero.css'
import './styles/Services.css'
import './styles/Projects.css'
import './styles/Contact.css'
import './styles/Responsiveness.css'
import ServiceComponent from './components/Service';
import ProjectComponent from './components/Project';
import ContactForm from './components/ContactForm';

// If you have a logo file, import it
// import logo from './logo.svg'; // Example logo import

function App() {
  return (
    <div className="App">
      <div className='header'>
        <div className='headerContent'>
        <div className="logo">
            {/* If you imported a logo, use it here like <img src={logo} alt="Logo" /> */}
            <img src="elh/logo.webp" alt="Logo" /> 
          </div>
          <div className="nav">
            <a href="#about">About</a>
            <a href="#services">Services</a>
            <a href="#projects">Projects</a>
            <a href="#contact">Contact</a>
            <div className='icons'>
              <a href="https://www.linkedin.com/in/younes-elhjouji/" target="_blank" rel="noreferrer">
                <div className='imgContainer'><img src='icons/linkedin.png' alt='linkedin'/></div>
              </a>
              <a href="https://resume.elhjouji.com" target="_blank" rel="noreferrer">
                <div className='imgContainer'><img src='icons/resume.png' alt='resume'/></div>
              </a>
            </div>
          </div>
          </div>
          </div>
      <div className="colorDiv heroSection" id="about">
        <div className="heroContent">
          {/* Use your profile image here */}
          <div className='heroIntro'>
            <h1>Hi 👋🏼,<br/> I am <span>Younes El Hjouji</span>,<br/> I build AI products</h1>
            <p>Leveraging 5 years of experience in software development, especially in Computer Vision and Generative AI.<br/>Contact me to discuss how my services fit for you!</p>
            <button className="contactButton"><a href="#contact" rel="noopener noreferrer">Get in touch!</a></button>
          </div>
          <div className="heroImageContainer">
            <img src="elh/hero-image.webp" alt="Younes El Hjouji" className="profileImage" />
          </div>
        </div>
        <div className='nextSection'>
          <p>What I do</p>
          <img className='arrowDown' src="icons/arrow-1.svg" alt='Arrow down'/>
        </div>
      </div>
      <div className="colorDiv services" id="services">
        <h3>I'm dedicated to bringing your innovative ideas to life with custom technology solutions. Specializing in AI software development, I offer a range of services designed to elevate your business. Whether you're a startup looking to disrupt the market or an established company seeking to enhance your digital presence, I'm here to help you navigate the complexities of modern technology.</h3>
        <div className='servicesView'>
          <ServiceComponent
          title='Early-stage Product Development'
          description='Transform your idea into a market-ready prototype where innovation meets efficiency.\n
          I manage the entire process of gathering requirements, architecting the product, coding, and deploying it to meet your highest standards.\n
          As your early-stage tech lead, I ensure your product excels in functionality, scalability, and documentation, setting the foundation for its success.'
          align={true}
        />
          <ServiceComponent
          title='AI Integration'
          description="Revolutionize existing software applications by adding intelligent AI features. I tailor AI capabilities to extend your product's value, ensuring it stays ahead in a rapidly evolving digital landscape.\n
          Streamline and enhance your business processes through strategic AI integration. \n
          By embedding AI into your workflows, we unlock efficiencies and insights, driving productivity and decision-making to new heights."
          align={false}
        />
          <ServiceComponent
          title='Strategy Consulting'
          description="My AI Strategy Consulting service demystifies the journey towards AI adoption, offering you strategic insights and expert guidance every step of the way.\n
          From aligning AI technologies with your business objectives to developing comprehensive strategies and providing ongoing support.\n 
          My approach includes hands-on advice on technology selection, product architecture, and design, ensuring your venture into AI is both successful and sustainable.
          "
          align={true}
        />
        </div>
        <div className='nextSection'>
          <p>Check out my work</p>
          <img className='arrowDown' src="icons/arrow-2.svg" alt='Arrow down'/>
        </div>
      </div>
      <div className="colorDiv works" id="projects">
        <div className='projectsView'>
        <ProjectComponent
        title='Cosmonio'
        roles={["Deep learning Engineer", "Customer Outreach"]}
        tags={['📷 Computer Vision', '💻 Desktop App']}
        logo="cosmonio/logo.webp"
        description='Dutch AI Startup developing interactive AI 
        product ‘NOUS’. It allows subject matter 
        experts to take control of the AI system and 
        interactively train it to analyse images.'
        button="Acquired by Intel in 2020"
        buttonColor='#5D605D'
        image='cosmonio/image.webp'
        link='https://northerntimes.nl/cosmonio-creates-innovative-ai-you-dont-have-to-be-in-silicon-valley-to-do-something-world-changing/'
        align={true}
        />
        <ProjectComponent
        title='Intel GETi'
        roles={["Architect", "AI Frameworks Engineer"]}
        tags={['📷 Computer Vision', '🧑‍💻 Web App']}
        logo="geti/logo.webp"
        description='Intel’s new software platform for building 
        computer vision models in a fraction of the
        time and with less data. '
        button="See more"
        buttonColor="#082F43"
        image='geti/image.webp'
        link="https://geti.intel.com"
        align={false}
        />
        <ProjectComponent
        title='Word Whisper'
        roles={["Product Owner", "Sole Developer"]}
        tags={['🧠 Generative AI', '📱 Mobile App']}
        logo="ww/logo.webp"
        description='Revolutionizing language learning through
        use of Generative AI, Interactive Notifications,
        and optimized learning methods.'
        button="Under Development"
        buttonColor="#5D605D"
        image='ww/image.webp'
        link=""
        align={true}
        />
        </div>
        <div className='nextSection'>
            <p>Book a free consultation</p>
            <img className='arrowDown' src="icons/arrow-2.svg" alt='Arrow down'/>
        </div>
      </div>
      <div className="colorDiv contact" id="contact">
        <div className='contactView'>
          <div className='contactPrompt'>
            <h1>Say 👋 Hi!</h1>
            <p>I enjoy discussing software 
              projects and AI ideas. 
              Please share as much info, 
              as possible so we can get 
              the most out of our first 
              catch-up.</p>
          </div>
          <ContactForm/>
        </div>
        {/* <ContactInfo/> */}
      </div>
    </div>
  );
}

export default App;
